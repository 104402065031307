import React, { FunctionComponent, useEffect, useState } from "react";
import Card from "./Card";

type Props = {
  onSuccess: () => void;
};

type CardType = {
  id: number;
  value: number;
  flipped: boolean;
  matched: boolean;
};

const Game: FunctionComponent<Props> = (props) => {
  const [cards, setCards] = useState<CardType[]>([]);
  const [flippedIndexes, setFlippedIndexes] = useState<number[]>([]);

  const won = cards.length > 0 && cards.every((card) => card.matched);
  console.log(cards);
  useEffect(() => {
    const cards = [];
    for (let i = 0; i < 20; i++) {
      cards.push({
        id: i,
        value: i % 10,
        flipped: false,
        matched: false,
      });
    }
    setCards(cards);
  }, []);

  const handleClick = (id: number) => {
    const newCards = cards?.map((card) => {
      if (card.id === id) {
        return {
          ...card,
          flipped: true,
        };
      }
      return card;
    });
    setCards(newCards);
    setFlippedIndexes((prevIndexes) => [...prevIndexes, id]);

    // Logic for two flipped cards
    if (flippedIndexes.length === 1) {
      const firstCard = cards[flippedIndexes[0]];
      const secondCard = cards[id];

      if (
        secondCard &&
        firstCard.value === secondCard.value &&
        firstCard.id !== secondCard.id
      ) {
        // Match is found
        setCards((prevCards) =>
          prevCards.map((card) =>
            card.value === firstCard.value ? { ...card, matched: true } : card,
          ),
        );
      } else {
        // No match found
        setTimeout(() => {
          setCards((prevCards) =>
            prevCards.map((card) =>
              card.id === firstCard.id || card.id === secondCard.id
                ? { ...card, flipped: false }
                : card,
            ),
          );
        }, 1000); // flip back after 1 second
      }
      setFlippedIndexes([]);
    } else if (flippedIndexes.length > 1) {
      // Prevent more than two cards from flipping at a time
      setFlippedIndexes([id]);
    }
  };

  useEffect(() => {
    if (won) {
      props.onSuccess();
    }
  }, [won]);

  return (
    <div className="p-6 bg-pink-200 h-lvh py-32">
      <div className="grid grid-cols-4 gap-3">
        {cards?.map((card) => (
          <Card
            onClick={handleClick}
            key={card.id}
            id={card.id}
            value={card.value}
            flipped={card.flipped}
            matched={card.matched}
          />
        ))}
      </div>
    </div>
  );
};

export default Game;
