import React, { FunctionComponent } from "react";

type Props = {};

const BackIcon: FunctionComponent<Props> = (props) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="w-1/2 h-1/2 text-pink-200 m-auto"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(2.000000, -0.005000)" fill="#434343">
          <path
            d="M4.781,3.986 L6.002,3.05 L7.219,3.986 L6.754,2.47 L7.973,1.532 L6.467,1.532 L6.002,0.016 L5.535,1.532 L4.028,1.532 L5.248,2.47 L4.781,3.986 Z"
            className="si-glyph-fill"
            fill="currentColor"
          ></path>

          <path
            d="M8.984,11.984 L10.918,12 L8.016,7.969 L10,7.969 L5.985,3.985 L2.031,8.001 L4.062,8.001 L1.015,11.954 L3.031,11.954 L0.015,15.97 L11.992,15.953 L8.984,11.984 L8.984,11.984 Z M4.016,15.016 L2.969,15.016 L2.969,13.954 L4.016,13.954 L4.016,15.016 L4.016,15.016 Z M5.016,11.016 L3.969,11.016 L3.969,9.954 L5.016,9.954 L5.016,11.016 L5.016,11.016 Z M6.031,7.016 L4.969,7.016 L4.969,5.954 L6.031,5.954 L6.031,7.016 L6.031,7.016 Z M6.969,9.953 L8.016,9.953 L8.016,11.015 L6.969,11.015 L6.969,9.953 L6.969,9.953 Z M7.969,15.016 L7.969,13.954 L9.016,13.954 L9.016,15.016 L7.969,15.016 L7.969,15.016 Z"
            className="si-glyph-fill"
            fill="currentColor"
          ></path>

          <rect
            x="10"
            y="8"
            width="0.969"
            height="0.984"
            className="si-glyph-fill"
            fill="currentColor"
          ></rect>

          <rect
            x="11"
            y="12"
            width="0.969"
            height="0.984"
            className="si-glyph-fill"
            fill="currentColor"
          ></rect>

          <rect
            x="0"
            y="12"
            width="0.969"
            height="0.984"
            className="si-glyph-fill"
            fill="currentColor"
          ></rect>

          <rect
            x="1"
            y="8"
            width="0.969"
            height="0.984"
            className="si-glyph-fill"
            fill="currentColor"
          ></rect>
        </g>
      </g>
    </svg>
  );
};

export default BackIcon;
