import React, { FunctionComponent } from "react";
import waldo from "../cards/waldo.png";
type Props = {
  onSuccess: () => void;
};

const Waldo: FunctionComponent<Props> = (props) => {
  return (
    <div className="h-lvh w-full  overflow-hidden bg-pink-200">
      <h2 className="text-pink-500 text-3xl p-3">Where's Waldo the Otter?</h2>
      <div className="relative">
        <img src={waldo} className={"rotate- bg-cover w-full object-cover"} />
        {/* click area */}
        <div
          onClick={props.onSuccess}
          className="w-16 h-24 absolute top-1/2 left-1/2 -translate-y-3/4 -translate-x-1/3"
        ></div>
      </div>
    </div>
  );
};

export default Waldo;
