import React, { FunctionComponent } from "react";
import back from "../cards/back.svg";
import { images } from "../cards";
import BackIcon from "./BackIcon";
type Props = {
  id: number;
  value: number;
  flipped: boolean;
  matched: boolean;
  onClick: (id: number) => void;
};

const Card: FunctionComponent<Props> = (props) => {
  return (
    <div
      className="overflow-hidden bg-pink-500 aspect-square rounded-md"
      onClick={() => props.onClick(props.id)}
    >
      {!props.flipped && !props.matched && (
        <div className="flex w-full h-full align-middle">
          <BackIcon />
        </div>
      )}
      {(props.flipped || props.matched) && (
        <img
          src={images[props.value]}
          alt="card"
          className="w-full h-full align-middle"
          style={{ objectFit: "cover" }}
        />
      )}
    </div>
  );
};

export default Card;
