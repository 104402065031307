import React, { FunctionComponent } from "react";

type Props = {};

const Congratulations: FunctionComponent<Props> = (props) => {
  return (
    <div className="grid place-items-center h-screen">
      <p className="text-3xl text-center text-pink-500 leading-snug">
        Congratulations! <br />
        You are the best Otter Spotter!
      </p>
      <p className="text-pink-500 p-5 text-justify">
        Korzystam z okazji, by powiedzieć Ci, jak ważną kobietą dla mnie jesteś.
        Cieszę się, że Cię poznałem i w pewnym momencie zaczęliśmy spędzać
        święta razem. Jesteś wartościową osobą, w którą bardzo wierzę. Jestem
        przekonany, że jesteś w stanie osiągnąć wszystko, czego tylko
        zapragniesz. Wierzę w Ciebie i w Twoje możliwości.
        <br /> <br />
        ❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️
        <br /> <br />
        Stworzenie dla Ciebie tego kalendarza było dużym wyzwaniem, ale też
        ogromną przyjemnością i zabawą. mam nadzieję, że podobało Ci się chociaż
        trochę.
        <br /> <br />
        ❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️
        <br /> <br />
        Dziękuję Ci za wszystko, co dla mnie robisz! Jesteś prawdziwym
        Jednorożcem 🦄 wśród ludzi. Jesteś magiczną istotą i gdybym Cię nie
        znał, a tylko słyszał o Tobie, to powiedziałbym, że to nowa część
        Harry'ego Pottera. Jesteś moją Hermioną Granger, która zawsze jest
        gotowa do pomocy i która jest niezwykle inteligentna. Jesteś moją Ginny
        Weasley, która jest odważna i zawsze gotowa do walki. Jesteś moją Luną
        Lovegood, która jest zawsze szczera i niezwykle mądra. Jesteś moją Molly
        Weasley, która jest zawsze gotowa do pomocy i która jest niezwykle
        inteligentna. ❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️
        <br /> <br />
      </p>
    </div>
  );
};

export default Congratulations;
