import React, { useState } from "react";
import Game from "./components/Game";
import Congratulations from "./components/Congratulations";
import Waldo from "./components/Waldo";

function App() {
  const [status, setStatus] = useState<"memory" | "waldo" | "win">("waldo");

  const handleMemorySuccess = () => {
    setStatus("win");
  };

  const handleWaldoSuccess = () => {
    setStatus("memory");
  };

  return (
    <>
      {status === "memory" && <Game onSuccess={handleMemorySuccess} />}
      {status === "waldo" && <Waldo onSuccess={handleWaldoSuccess} />}
      {status === "win" && <Congratulations />}
    </>
  );
}

export default App;
